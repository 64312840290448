import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_TRUCHIRO_V1_API_URL;

function createUrl(base, path) {
  return `${base}${path}`;
}

function createAuthHeader(token) {
  return {
    authorization: `Bearer ${token}`,
  };
}

//request structure for each of the endpoints in this API
export function createAvatar(auth, data) {
  return [
    createUrl(BASE_URL, '/user/avatar'),
    {
      method: 'post',
    },
    data,
    auth,
  ];
}

export function createClient(auth, client) {
  return [
    createUrl(BASE_URL, `/client`),
    {
      method: 'post',
    },
    client,
    auth,
  ];
}

export function getClients(auth, params = null) {
  return [
    createUrl(BASE_URL, '/clients'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getClient(auth, clientId) {
  return [
    createUrl(BASE_URL, `/client/${clientId}`),
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function updateClient(auth, client) {
  return [
    createUrl(BASE_URL, `/client/${client.clientId}`),
    {
      method: 'patch',
    },
    client,
    auth,
  ];
}

export function deleteClient(auth, clientId) {
  return [
    createUrl(BASE_URL, `/client/${clientId}`),
    {
      method: 'delete',
    },
    null,
    auth,
  ];
}

export function createSession(auth, clientId, session) {
  return [
    createUrl(BASE_URL, `/client/${clientId}/session`),
    {
      method: 'post',
    },
    session,
    auth,
  ];
}

export function getSessions(auth, clientId, params = null) {
  return [
    createUrl(BASE_URL, `/client/${clientId}/sessions`),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getSession(auth, clientId, sessionId) {
  return [
    createUrl(BASE_URL, `/client/${clientId}/session/${sessionId}`),
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function createUser(auth, user) {
  return [
    createUrl(BASE_URL, '/user'),
    {
      method: 'post',
    },
    user,
    auth,
  ];
}

export function getUsers(auth, params = null) {
  return [
    createUrl(BASE_URL, '/users'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth,
  ];
}

export function getUser(auth, therapistId) {
  return [
    createUrl(BASE_URL, `/users/${therapistId}`),
    {
      method: 'get',
    },
    null,
    auth,
  ];
}

export function updateUser(auth, user) {
  return [
    createUrl(BASE_URL, `/user/${user.therapistId}`),
    {
      method: 'patch',
    },
    user,
    auth,
  ];
}

export function disableUser(auth, therapistId) {
  return [
    createUrl(BASE_URL, `/user/${therapistId}/disable`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function enableUser(auth, therapistId) {
  return [
    createUrl(BASE_URL, `/user/${therapistId}/enable`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function resetPassword(auth, therapistId) {
  return [
    createUrl(BASE_URL, `/user/${therapistId}/resetPassword`),
    {
      method: 'patch',
    },
    null,
    auth,
  ];
}

export function deleteUser(auth, therapistId) {
  return [
    createUrl(BASE_URL, `/user/${therapistId}`),
    {
      method: 'delete',
    },
    null,
    auth,
  ];
}

export default async function request([url, options, data, auth]) {
  try {
    let token = null;
    if (auth) {
      token = await auth.getAccessToken();
    }
    for (const attribute in data) {
      if (data[attribute] === '' || data[attribute] === null) {
        delete data[attribute];
      }
    }
    return (
      await axios.request({
        url: url,
        method: options.method || 'get',
        ...{
          headers: options.headers || null,
        },
        ...{
          headers: token && createAuthHeader(token),
        },
        params: options.params || null,
        data: data,
        responseType: options.responseType || 'json',
      })
    ).data;
  } catch (err) {
    if (err.response) {
      throw {
        error: err.response.data,
        statusCode: err.response.status,
      };
    } else if (err.request) {
      throw {
        error: 'The request was made but no response was received.',
        statusCode: 408,
      };
    } else {
      throw {
        error: err.message,
        statusCode: 0,
      };
    }
  }
}
