import axios from 'axios';
import request, { createSession as getOptions } from '../requests.mjs';

export async function createSession(auth, clientId, data) {
  try {
    const response = await request(getOptions(auth, clientId, data));
    return [response, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function uploadSession(url, file) {
  try {
    const response = await axios.put(url, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return [true, null];
  } catch (err) {
    console.log(err);
    return [null, 'Your session data was not uploaded.'];
  }
}
