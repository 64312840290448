import { useRef, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Legend,
  LabelList,
  Tooltip,
} from 'recharts';
import { round } from 'lodash';
import {
  NICounter,
  arcSegments,
  renderEventGraphs,
  renderHeartGraph,
  featureNames,
  barGraphColorMapping,
  CustomLegend,
} from '../../../components/organGraph/common';
import { Box, Typography, Unstable_Grid2 as Grid, Stack } from '@mui/material';
import Gauge from './GaugeGraph';
import HRVGraphic from '../../../components/organGraph/LungsHeart';
import { idealHRVData } from '../../../IdealData';
import LoadSpinner from '../../../components/LoadSpinner';

const CustomTooltip = ({ payload }) => {
  const xAxisLabel =
    payload && payload.length > 0 && payload[0]?.payload && payload[0]?.payload.featureName
      ? payload[0]?.payload.featureName
      : '';

  return (
    <Box
      style={{
        backgroundColor: 'white',
        padding: '1rem',
        outline: '1px solid black',
      }}
      display="flex"
      flexDirection="column"
      alignItems="left"
      justifyContent="left"
      maxWidth="14rem"
      minWidth="14rem"
    >
      <Typography variant="h6">{xAxisLabel}</Typography>
      {payload && payload.length > 0 ? (
        <Box mt="0.75rem" display="flex" flexDirection="column" alignItems="flex-start">
          {payload.map((payloadObj, index) => {
            return (
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                key={`tooltip-label-${index}`}
                marginBottom={index + 1 === payload.length ? 'auto' : '0.5rem'}
              >
                <Typography variant="body1" color={payloadObj.color}>
                  {payloadObj.name}:
                </Typography>
                <Typography variant="body1" color={payloadObj.color}>
                  {payloadObj.value}%
                </Typography>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box mt="0.75rem"></Box>
      )}
    </Box>
  );
};

const HRVBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={600}
        height={300}
        data={data}
        barGap={8}
        barSize={35}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="featureName" />
        <YAxis
          dx={-7}
          allowDecimals={true}
          style={{ textAnchor: 'middle' }}
          ticks={[0, 20, 40, 60, 80, 100]}
          inteval={0}
        >
          <Label
            value="HRV (%)"
            position={'insideLeft'}
            style={{
              fill: 'rgba(0, 0, 0, 0.87)',
              textAnchor: 'middle',
            }}
            angle={-90}
          />
        </YAxis>

        {featureNames.map((featureName) => (
          <Bar dataKey={featureName} fill={barGraphColorMapping[featureName]} key={featureName}>
            <LabelList
              dataKey={featureName}
              position="top"
              style={{ fontSize: '14px' }}
              formatter={(val) => round(val)}
            />
          </Bar>
        ))}

        <Legend content={<CustomLegend />} />
        {/* <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgb(128,128,128, 0.2)' }} /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

const HRVGraph = ({ session, loading }) => {
  let tempIndex = session?.computed_data.hrv_resilence_index * 100;
  const measurementValue = tempIndex.toFixed(2);
  const eventGraphRef = useRef();
  const maxRange = 0.9;
  const minRange = 0.7;
  const [value, setValue] = useState([]);

  function convertBandLabel(band) {
    switch (band) {
      case 'hrv_vlf':
        return 'Very Low';
      case 'hrv_lf':
        return 'Low';
      case 'hrv_hf':
        return 'High';
      default:
        return '';
    }
  }

  useEffect(() => {
    if (!eventGraphRef.current) return;
    renderEventGraphs(measurementValue, eventGraphRef);
    renderHeartGraph(measurementValue, eventGraphRef);
    let temp = [];

    // Transform raw data for graph
    ['hrv_vlf', 'hrv_lf', 'hrv_hf'].forEach((band) => {
      temp.push({
        featureName: convertBandLabel(band),
        'Eyes Open BL': session?.raw_data[band].eyes_open_baseline,
        'Eyes Closed BL': session?.raw_data[band].eyes_closed_baseline,
        'Math Test': session?.raw_data[band].math_task,
        'Recovery 1': session?.raw_data[band].recovery_1,
        Sounds: session?.raw_data[band].distracting_sounds_task,
        'Recovery 2': session?.raw_data[band].recovery_2,
        'HRV Task': session?.raw_data[band].hrv_task,
        'Recovery 3': session?.raw_data[band].recovery_3,
      });
    });

    setValue(temp);
  }, []);

  return (
    <>
      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12}>
              <Typography variant="h5" style={{ fontWeight: 800 }}>
                HRV Resilience Index
              </Typography>
              <Box marginTop="0.25rem">
                <NICounter niValue={measurementValue} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Gauge
                min={0}
                max={100}
                value={measurementValue}
                maxAngle={90}
                minAngle={-90}
                tickCount={6}
                arcSegments={arcSegments}
                minRangeValue={minRange}
                maxRangeValue={maxRange}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <HRVGraphic eventGraphRef={eventGraphRef} measurementValue={measurementValue} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                IDEAL HRV
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                mt={2}
                pl={6}
                p2={2}
              >
                <Typography variant="h6">
                  &nbsp;
                </Typography>
              </Stack>
              <HRVBarChart data={idealHRVData} />
            </Grid>
            <Grid justifyItems="center" xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                YOUR HRV
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                mt={2}
                pl={6}
                p2={2}
              >
                <Typography variant="h6">
                  Very Low: {session?.computed_data.vlf_average.toFixed(2) * 100}%
                </Typography>
                <Typography variant="h6">
                  Low: {session?.computed_data.lf_average.toFixed(2) * 100}%
                </Typography>
                <Typography variant="h6">
                  High {session?.computed_data.hf_average.toFixed(2) * 100}%
                </Typography>
              </Stack>
              <HRVBarChart data={value} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default HRVGraph;
